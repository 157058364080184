import React, { useEffect, useState } from "react";
import EazyHeader from "../../../components/Header";
import Layout from "../../../components/Layout";
import { Helmet } from "react-helmet";
import "./solutions.css";
import "../../../../src/commonStyle/style.css";
import ThumbnailImage from "../../../images/hyperlocal.png";
import Grocery from "../../../images/Grocery_img.webp";
import Restaurants from "../../../images/Restaurants.webp";
import Medicine from "../../../images/Medicine-Copy.webp";
import Bakery from "../../../images/Bakery.webp";
import Fish from "../../../images/Fish.webp";
import Diary from "../../../images/Diary.webp";
import EmbraceConvenience from "../../../images/Embrace-Convenience.webp";
import orderManagementImg from "../../../images/order-management.webp";
import deliveryImg from "../../../images/delievery-mang.webp";
import DelightCustomersImg from "../../../images/Customers.webp";
import ZoppingImg from "../../../images/ZoppingAdvantage.webp";
import NotificationsImg from "../../../images/Notifications.webp";
import { Message } from "../../../lib/getMessages";
import { navigate } from "@reach/router";
import loadable from "@loadable/component";
import WithProviders from "../../../components/WithProviders";
const Popup = loadable(() => import("../../../components/Popup"));
const SignupForm = loadable(() => import("../../../components/SignupForm"));
const Solutions = (props) => {
  const { language, updateLanguage, direction } = props;
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      setIsLoggedin(true);
    }
  }, []);

  const HandleClickSignupForm = () => {
    setShowSignupModal(!showSignupModal);
  };

  const HandleSelectStoreForFreeClicked = () => {
    if (!isLoggedin) {
      HandleClickSignupForm();
    } else {
      window.open("/admin/home/dashboard", "_blank");
    }
  };
  const sellingData = [
    {
      icon: Grocery,
      alt: "grocery",
      dictKey: "Grocery",
    },
    {
      icon: Restaurants,
      alt: "Food",
      dictKey: "Food.and.Beverage",
    },
    {
      icon: Medicine,
      alt: "medicine",
      dictKey: "Medicine",
    },
    {
      icon: Bakery,
      alt: "bakery",
      dictKey: "Bakery",
    },
    {
      icon: Diary,
      alt: "diary",
      dictKey: "Diary",
    },
    {
      icon: Fish,
      alt: "fish",
      dictKey: "Fish",
    },
  ];
  const featuresData = [
    {
      img: EmbraceConvenience,
      alt: "unique online store",
      dictKey: "Embrace.Convenience",
      description: [
        "Embrace.Convenience.discription1",
        "Embrace.Convenience.discription2",
      ],
    },
    {
      img: orderManagementImg,
      alt: "order management",
      dictKey: "Flexible.Order.Management",
      description: [
        "Flexible.Order.Management.description1",
        "Flexible.Order.Management.description2",
        "Flexible.Order.Management.description3",
        "Flexible.Order.Management.description4",
      ],
    },
    {
      img: deliveryImg,
      alt: "delivery management",
      dictKey: "Simplified.Delivery.Management",
      description: [
        "Simplified.Delivery.Management.description1",
        "Simplified.Delivery.Management.description2",
      ],
    },
    {
      img: DelightCustomersImg,
      alt: "order management",
      dictKey: "Delight.your.customers",
      description: [
        "Delight.your.customers.description1",
        "Delight.your.customers.description2",
      ],
    },
    {
      img: ZoppingImg,
      alt: "Zopping advantage",
      dictKey: "Zopping.Advantage",
      description: [
        "Zopping.Advantage.description1",
        "Zopping.Advantage.description2",
        "Zopping.Advantage.description3",
      ],
    },
    {
      img: NotificationsImg,
      alt: "push notifications",
      dictKey: "Engage.your.customer.with.Push",
      description: ["Engage.your.customer.with.Push.description1"],
    },
  ];
  let canonicalUrl = `https://staging.zopping.com/solutions/hyperlocal`;
  if (typeof window !== "undefined") {
    if (window.location.host === "zopping.com") {
      canonicalUrl = `https://zopping.com/solutions/hyperlocal`;
    }
  }
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      isDirectionRTL={isDirectionRTL}
      language={language}
      location={props.location}
    >
      <div className="eazy-page">
        <Helmet title="Zopping - Hyperlocal">
          <meta property="og:image" content={ThumbnailImage} />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <EazyHeader
          language={language}
          updateLanguage={updateLanguage}
          current="solutions/hyperlocal"
          isDirectionRTL={isDirectionRTL}
        />
        <div className="eazy-solutions">
          <section className="overview">
            <div className="overview-heading">
              <h2>
                <span>
                  <Message dictKey="Hyperlocal" />
                </span>
              </h2>
              <p>
                <span>
                  <Message dictKey="Hyperlocal.descroption" />
                </span>
              </p>
              <div className="createbtn-wrp">
                <button
                  className="create-store-btn"
                  onClick={HandleSelectStoreForFreeClicked}
                >
                  <Message dictKey="Create.Your.Online.Store" />
                </button>
              </div>
            </div>
          </section>
          <div className="hyperlocal-wrp">
            <section className="eazy-solutions-selling">
              <h1>
                <span className="translation-span">
                  <Message dictKey="Best.suited.for.selling" />
                </span>
              </h1>
              <hr className="desktop-border"></hr>
              <hr className="mobile-border-top"></hr>
              <div className="icon-wrapper">
                {sellingData?.map((items, index) => {
                  return (
                    <div className="Img-wrapper" key={index}>
                      <div className="grocery-img-wrp">
                        <img
                          src={items?.icon}
                          alt={items?.alt}
                          className="grocery-img"
                        />
                      </div>
                      <h3>
                        <span className="icon-desc-text-wrp">
                          <Message dictKey={items?.dictKey} />
                        </span>
                      </h3>
                    </div>
                  );
                })}
              </div>
            </section>
            <section className="features wrap">
              {featuresData?.map((items, index) => {
                return (
                  <div
                    className={
                      index % 2 === 0 ? "sell-things" : "sell-things-rev"
                    }
                  >
                    <section
                      className={
                        index % 2 === 0
                          ? "feature unique-online-store solutions"
                          : "feature quality-website rev solutions"
                      }
                    >
                      <div className="feature-media">
                        <img
                          src={items?.img}
                          alt={items?.alt}
                          width="580"
                          height="471"
                        />
                      </div>
                      <div className="feature-details">
                        <h2>
                          <Message dictKey={items?.dictKey} />
                        </h2>
                        {items?.description?.map((descrip) => {
                          return (
                            <p>
                              <span>
                                <Message dictKey={descrip} />
                              </span>
                            </p>
                          );
                        })}
                      </div>
                    </section>
                  </div>
                );
              })}
            </section>
            <div className="Btn-wrp">
              <button
                className="create-store-btn"
                onClick={HandleSelectStoreForFreeClicked}
              >
                <Message dictKey="Create.Your.Online.Store" />
              </button>
            </div>
          </div>
          <Popup show={showSignupModal} close={HandleClickSignupForm}>
            <SignupForm
              close={HandleClickSignupForm}
              showLogin={HandleClickSignupForm}
              setIsLoggedIn={setIsLoggedin}
            />
          </Popup>
        </div>
      </div>
    </Layout>
  );
};
export default WithProviders(Solutions);
